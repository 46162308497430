import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { CreateDepartmentAPI, UpdateDepartmentAPI } from "../../../lib/api";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import Cookies from "js-cookie";
import { useState } from "react";

const CreateDepartment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDepartment = location?.state?.GetDepartment;
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      departmentName: isDepartment?.DepName || "",
      DepPrefix: isDepartment?.DepPrefix || "",
      status: isDepartment?.Status === true ? true : false,
    },
  });
  const mutate = useMutation({
    mutationFn: (data) => UpdateDepartmentAPI(data,isDepartment.DepID ),
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      toast.success("Update Department Successful", {
        position: "top-right",
        closeOnClick: true,
      });
      navigate(RouteNavigation.departments);
    },
    onError: () => {
      toast.error("Update Department Failed", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const handleCounterSubmit = async (data) => {
    setIsLoading(true);
    const dataResponse = await CreateDepartmentAPI(data);
    try {
      if (dataResponse.status === 201) {
        toast.success("Create Department Successful", {
          position: "top-right",
          closeOnClick: true,
        });
        navigate(RouteNavigation.departments);
      } else {
        toast.error("Create Department Failed " + dataResponse.error || "", {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const CompanyId = Cookies.get("CompanyId");
    if (isDepartment) {
      const updatedData = {
        DepName: data.departmentName,
        Status: data.status,
        DepPrefix: data.DepPrefix,
        CompanyID: CompanyId,
      };
      mutate.mutate(updatedData);
    } else {
      const updatedData = {
        CompanyID: CompanyId,
        DepName: data.departmentName,
        Status: data.status,
        DepPrefix : data.DepPrefix,
       
      };
      handleCounterSubmit(updatedData);
    }
  };
  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">
        {isDepartment ? "Update" : "Create"} Department
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full mt-5"
      >
        <div className="flex gap-10">
          <div className="flex flex-col">
            <label className="font-semibold">Department Name</label>
            <input
              className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
              {...register("departmentName", { required: true })}
            />
            {errors.departmentName && (
              <span className="text-red-600">This field is required</span>
            )}
          </div>

          <div className="flex flex-col">
            <label className="font-semibold">Department Prefix</label>
            <input
              className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
              {...register("DepPrefix", { required: true })}
            />
          </div>
        </div>

        <div className="flex gap-4 mt-2">
          <input {...register("status")} type="checkbox" />
          <span>Active</span>
        </div>
        <div className="flex gap-5 justify-end">
          <Link to={RouteNavigation.departments}>
            <button className="flex items-center justify-center h-8 px-6 bg-gray-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-gray-700">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="flex items-center justify-center h-8 px-6 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          >
            {isLoading ? "Loading..." : `${isDepartment ? "Update" : "Create"}`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateDepartment;
