import { useNavigate } from "react-router-dom";
import { GetDepartmentForCoordinator } from "../../../lib/api";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation } from "../../../components/common/Animation";
import Cookies from "js-cookie";

const AvailableCounter = () => {
  const navigate = useNavigate();
  // const userId = Cookies.get("userId");
  const { isLoading, isPending, data } = useQuery({
    queryKey: ["GetDepartmentForCoordinator"],
    queryFn: () => GetDepartmentForCoordinator(),
  });
  // console.log(GetDepartmentForCoordinator);
  

  const filteredData = data?.data?.counters?.filter(
    (item) => item.Status === true
  );

  if (filteredData?.length === 1) {
    if (Cookies.get("isCustomCall") === "true") {
      navigate(`/custom-counter/${filteredData[0]?.CounterID}`, {
        state: { userCounterData: filteredData[0] },
      });
    } else {
      navigate(`/counter/${filteredData[0]?.CounterID}`, {
        state: { userCounterData: filteredData[0] },
      });
    }
  }

  const handleNavigation = (counter, DepID) => {
    Cookies.set("DepID", DepID);
    console.log("Saved cookie:", DepID);

    if (Cookies.get("isCustomCall") === "true") {
      navigate(`/custom-counter/${counter.CounterID}`, {
        state: { userCounterData: counter, DepID: DepID },
      });
    } else {
      navigate(`/counter/${counter.CounterID}`, {
        state: { userCounterData: counter, DepID: DepID },
      });
    }
  };

  const isExpired = (expireDate) => {
    const currentDate = new Date();
    const depExpireDate = new Date(expireDate);
    return depExpireDate <= currentDate;
  };

  return (
    <div className="py-5 px-4 lg:px-0 max-w-[1024px] mx-auto">
      <h4 className="text-2xl lg:text-3xl font-bold mb-5 text-center lg:text-left">
        Dashboard
      </h4>
      {isLoading && isPending && (
        <div className="w-44 m-auto">
          <ShowLoadingAnimation />
        </div>
      )}
      {data?.data?.department?.department?.map((dept) => {
        const expired = isExpired(dept.DepExpire);
     
        // {data?.data?.department?.department
        //   ?.filter((dept) => dept?.assignedUsers?.includes(userId))
        //   .map((dept) => {
        //     const expired = isExpired(dept.DepExpire);
        return (
          <div key={dept.DepID} className=" p-4 mb-5 shadow-2xl"  >
          <p className="text-xl lg:text-2xl font-bold border-b-3 border-b-indigo-300 text-black w-fit text-center mx-auto lg:mx-0">
            {dept.DepName}{" "}
            <span
              className={`text-sm ${expired ? "text-red-500" : "text-green-500"}`}
            >
              {expired ? "(Expired)" : "(Active)"}
            </span>
          </p>
        
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mb-5">
            {dept?.Counters?.map((Counter, index) => {
              const isDisabled = expired;
        
              return (
                <div
                  key={index + 1}
                  onClick={() =>
                    !isDisabled && handleNavigation(Counter, dept.DepID)
                  }
                  className={`w-full bg-cover bg-center bg-no-repeat mx-auto mt-2 rounded-xl border-4 ${
                    isDisabled
                      ? "border-gray-300 cursor-not-allowed opacity-50"
                      : "border-[#3c73f6] cursor-pointer hover:shadow-[5px_5px_0_0_rgba(0,0,0,1)] hover:shadow-[#3c73f6]"
                  } p-5 sm:p-6 md:p-8 lg:p-10 text-center transition-all duration-300`}
                >
                  <p className="text-lg sm:text-xl md:text-2xl font-bold border-b-4 border-b-indigo-300 text-black">
                    {Counter.CounterName}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        
        );
      })}
    </div>
  );
};

export default AvailableCounter;
