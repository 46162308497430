import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useQuery } from "@tanstack/react-query";
import { GetAllDetailReport } from "../../../lib/api";
import {
  ShowLoadingAnimation,
  ShowNoDataAnimation,
} from "../../../components/common/Animation";
import { TextField } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import Flatpickr from "react-flatpickr";
import { useState } from "react";

const DetailReport = () => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;

  const startDate = dateRange[0]
    ? dateRange[0].toISOString().split("T")[0]
    : null;
  const endDate = dateRange[1]
    ? dateRange[1].toISOString().split("T")[0]
    : null;

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["GetAllDetailReport", startDate, endDate, pageNumber],
    queryFn: () => GetAllDetailReport(startDate, endDate, pageNumber, pageSize),
    keepPreviousData: true,
  });
  // console.log(data?.data?.tokenDetails?.tokenDetails?.data);

  const isUserData = data?.data?.tokenDetails?.tokenDetails?.data;

  const handleSearch = () => {
    setPageNumber(1);
    refetch();
  };

  const handlePageChange = (_, newPage) => {
    setPageNumber(newPage);
  };

  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">Token Detail Report</h4>

      <div className="flex flex-col md:flex-row justify-between items-center mt-4">
        <div id="date-range-picker" className="flex items-center mb-4 md:mb-0">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
            <Flatpickr
              value={dateRange}
              onChange={(dates) => setDateRange(dates)}
              options={{ mode: "range", dateFormat: "Y-m-d" }}
              className="bg-gray-50 border border-gray-300 h-full mr-2 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select date range"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center ">
          <TextField
            variant="outlined"
            label="Phone Number"
            type="number"
            className="justify-end md:w-38 mb-4 md:mb-0"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              style: { height: "40px" },
            }}
          />
          <button
            onClick={handleSearch}
            className="ml-2 p-2 bg-blue-500 text-white rounded-md h-10 flex items-center justify-center"
            style={{ minWidth: "48px" }}
          >
            <FaSearch className="w-5 h-5" />
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="w-44 flex justify-center m-auto">
          <ShowLoadingAnimation />
        </div>
      ) : (
        <div className="w-full">
          <div className="mt-5 border-2 rounded-lg">
            <div className="overflow-auto">
              <TableContainer>
                <Table>
                  <TableHead className="bg-gray-800 rounded-xl">
                    <TableRow>
                      <TableCell style={{ color: "white" }}>Date</TableCell>
                      <TableCell style={{ color: "white" }}>
                        Token Number
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Customer Name
                      </TableCell>
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Mobile Number
                      </TableCell>
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Token Generated Time
                      </TableCell>
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Department Name
                      </TableCell>
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Counter Name
                      </TableCell>
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Called Time
                      </TableCell>
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!isLoading && !isError && (
                    <TableBody>
                      {isUserData && isUserData.length > 0 ? (
                        isUserData.map((user, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {user.CreatedDate
                                ? new Date(user.CreatedDate)
                                    .toISOString()
                                    .split("T")[0]
                                : "N/A"}
                            </TableCell>
                            <TableCell>{user.TokenValue}</TableCell>
                            <TableCell>{user.CustomerName}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {user.CustomerMobileNumber}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {user.StatusCreatedTime}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {user.DepFromName}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {user.CounterName}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {user.OnCallTime}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {user.CurrentTokenStatus}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        {isError && !isUserData && (
          <div className="w-44 flex justify-center">
            <ShowNoDataAnimation />
          </div>
        )}
      </div>
      <div className="flex justify-center items-center w-full my-4">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(data?.data?.totalCount / pageSize)}
            page={pageNumber}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </div>
    </div>
  );
};

export default DetailReport;
