import React from "react";

const summaryReport = ({
  header,
  Tittle,
  Served,
  Transferred,
  Average,
  Peak,
  Counter,
  Unserved,
  headerNew,
}) => {
  return (
    <div className="relative flex gap-4 mt-2 items-start justify-center">
    <svg
      className="absolute top-0 right-0"
      width="158"
      height="119"
      aria-hidden="true"
    >
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="96.22%" id="a">
          <stop stopColor="#818CF8" stopOpacity=".88" offset="0%" />
          <stop stopColor="#818CF8" stopOpacity=".24" offset="100%" />
        </linearGradient>
      </defs>
      <g
        transform="translate(-719 -96)"
        stroke="url(#a)"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M802.959 69.706c..." />
      
      </g>
    </svg>
  
    <div className="flex flex-col items-center relative z-10">
      <div
        className="bg-gradient-to-r from-blue-500 to-blue-700 rounded-[9px] p-5 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] w-[230px] h-[200px] transition duration-300 ease-in-out hover:shadow-[rgba(50,50,93,0.4)_0px_12px_24px_-4px,_rgba(0,0,0,0.5)_0px_6px_14px_-6px] hover:scale-105 flex flex-col justify-center items-center text-center"
      >
        <span className="text-xl font-semibold text-white ">
          {Tittle} {Served} {Transferred} {Average} {Peak} {Counter} {Unserved}
        </span>
        <h3 className="font-semibold text-white text-lg">{header}</h3>
        <h3 className="font-semibold text-amber-100 text-lg">{headerNew}</h3>
      </div>
    </div>
  </div>
  
  
  );
};

export default summaryReport;
