import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { RouteNavigation } from "../../../Routes";
import { GetAllUser } from "../../../lib/api";
import {
  ShowLoadingAnimation,
  ShowNoDataAnimation,
} from "../../../components/common/Animation";
import { FaRegEdit } from "react-icons/fa";
// import { GrFormView } from "react-icons/gr";

const UserList = () => {
  const { isLoading, isPending, isError, data } = useQuery({
    queryKey: ["GetAllUser"],
    queryFn: () => GetAllUser(),
  });
  console.log(data?.data?.users?.users?.Status);
  
  const navigate = useNavigate();
  const isUserData = data?.data?.users;
  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">Users</h4>
      <div className="flex justify-end">
        <Link to={RouteNavigation.create_user}>
          <button className="bg-gray-800 hover:bg-gray-600 text-white px-4 py-2 rounded-md">
            Create New
          </button>
        </Link>
      </div>
      {isLoading || isPending ? (
        <div className="w-44 flex justify-center m-auto">
          <ShowLoadingAnimation />
        </div>
      ) : (
        <div className="w-full">
          <div className="mt-5 border-2 rounded-lg">
            <div className="overflow-auto">
              <TableContainer>
                <Table>
                  <TableHead className="bg-gray-800 rounded-xl">
                    <TableRow>
                      <TableCell style={{ color: "white" }}>Sl.No</TableCell>
                      <TableCell style={{ color: "white" }}>
                        User Name
                      </TableCell>
                      <TableCell style={{ color: "white" }}>Type</TableCell>
                      {/* <TableCell style={{ color: "white" }}>
                        Department Name
                      </TableCell> */}
                      <TableCell
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!isPending && !isLoading && !isError && (
                    <TableBody>
                      {data?.data?.users?.users?.map((user, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{user.Username}</TableCell>
                          <TableCell>{user.UserType}</TableCell>
                          {/* <TableCell>{user.DepName}</TableCell> */}
                          <TableCell
                            onClick={() =>
                              navigate("/create-user", {
                                state: { userData: user },
                              })
                            }
                          >
                            <div className="flex justify-center">
                              <FaRegEdit
                                className="cursor-pointer"
                                size={"20px"}
                                color={user.Status === true ? "green" : "red"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        {isError && !isUserData && (
          <div className="w-44 flex justify-center">
            <ShowNoDataAnimation />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
